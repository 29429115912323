<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container journaling">
            <!-- 头部banner图 -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">{{ title }}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="container">
              <!-- <div class="row">
                <div class="col-xs-12 col-sm-2" style="font-size: 46px">
                  Quarterly Reports
                </div>
                <div class="col-xs-12 col-sm-9 col-sm-offset-1">
                  <el-collapse
                    v-model="activeNames"
                    @change="handleChange"
                    v-for="(item, index) in tableList"
                    :key="index"
                  >
                    <el-collapse-item :title="item.tableName" :name="index + 1">
                      <div>
                        <el-table :data="tableListValue" style="width: 100%">
                          <el-table-column
                            v-for="(item2, index2) in tableHead[index].length"
                            :key="index2"
                            :label="tableHead[index][index2]"
                            width="180"
                          >
                            <template slot-scope="scope">
                              <div
                                v-if="tableTyoe(scope.row, index, index2) == 0"
                              >
                                {{ tableListCom(scope.row, index, index2) }}
                              </div>
                              <div v-else>
                                <a
                                  :href="tableLink(scope.row, index, index2)"
                                  target="_blank"
                                >
                                  {{
                                    tableListCom(scope.row, index, index2)
                                  }}</a
                                >
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div> -->
              <!-- 新 -->
              <div class="row jouMain">
                <div class="header">
                  <span>{{ tabletitle }}</span>
                </div>
                <el-table
                  :data="item1.tableListValue"
                  :span-method="objectSpanMethod"
                  border
                  stripe
                  style="width: 100%"
                  v-for="(item1, index1) in tableList"
                  :key="index1"
                >
                  <el-table-column
                    :label="item1.tableHead[index2]"
                    v-for="(item2, index2) in item1.tableHead.length"
                    :key="index2"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <!-- {{ tableListCom(scope.row, index1, index2) }} -->
                      <div
                        v-if="tableType(scope.row, index1, index2) == 0"
                        :class="[
                          tableTime(scope.row, index1, index2) == 'Date'
                            ? 'time'
                            : '',
                        ]"
                      >
                        {{ tableListCom(scope.row, index1, index2) }}
                      </div>
                      <div
                        v-else-if="tableType(scope.row, index1, index2) == 1"
                      >
                        <a
                          :href="tablePdf(scope.row, index1, index2)"
                          target="_blank"
                          class="link"
                        >
                          {{ tableListCom(scope.row, index1, index2) }}</a
                        >
                      </div>
                      <div
                        v-else-if="tableType(scope.row, index1, index2) == 2"
                      >
                        <a
                          :href="tableLink(scope.row, index1, index2)"
                          target="_blank"
                          class="link"
                        >
                          {{ tableListCom(scope.row, index1, index2) }}</a
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "../../components/header/header.vue";
import Footers from "../../components/footer/footer.vue";
import { queryTable } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: this.seoContent.seoTitle + "- 盈喜集团股份有限公司|ATXG",
      meta: [
        {
          name: "keywords",
          content: this.seoContent.seoKeywords,
        },
        {
          name: "description",
          content: this.seoContent.seoDescription,
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {
      title: "", //标题
      tabletitle: "", // 表格标题
      activeNames: [1],
      // 表格数据
      tableList: [],
      // 表格表头
      tableHead: [],
      // 表格每行内容
      tableListValue: [],
      // 最大合并行数
      tableMerge: 0,
      seoContent: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleChange(val) {
      console.log(val);
    },
    // 加载
    async init() {
      let that = this;
      // this.title = this.$route.query.tableTitle;
      this.title = "投资者演示文稿";
      // this.tabletitle = this.$route.query.title;
      this.tabletitle = "Investor PPT";
      const { data: res } = await this.$http.get(queryTable, {
        params: { pageCode: "demonstration", pageSize: 10000 },
      });
      console.log(res);

      let arrList = [];
      res.result.records.forEach(function (item, index) {
        let arr = [];
        let arrHead = [];
        arrHead = JSON.parse(item.tableHead);
        that.seoContent = {
          seoTitle: item.seoTitle,
          seoKeywords: item.seoKeywords,
          seoDescription: item.seoDescription,
        };
        item.tableRowList.forEach(function (item2, index2) {
          arr.push(JSON.parse(item2.rowValue));
          // console.log(arr + index + index2)
          arrList[index] = {
            tableListValue: arr,
            tableHead: arrHead,
          };
          if (that.tableMerge < arrList[index].tableListValue.length) {
            that.tableMerge = arrList[index].tableListValue.length;
          }
          // console.log(that.tableMerge)
        });
      });
      this.tableList = arrList;
      // this.tableListValue = arr
      // this.tableHead = arrHead
      console.log(this.tableList);
      // console.log(that.tableMerge)
      // console.log(this.tableListValue)
    },
    // 判断表格类型
    tableType(val, index1, index2) {
      let type = this.tableList[index1].tableHead[index2] + "type";
      // console.log(val[type].charAt(val[type].indexOf(',') + 1))
      return val[type].charAt(val[type].indexOf(",") + 1);
    },
    // 插值表达式
    tableListCom(val, index1, index2) {
      let head = this.tableList[index1].tableHead[index2] + "value";
      return val[head];
    },
    // 跳转PDF页面
    tablePdf(val, index1, index2) {
      let file = this.tableList[index1].tableHead[index2] + "file";
      // console.log(this.$fileurl + val[file])
      return this.$fileurl + val[file];
    },
    // 跳转外链
    tableLink(val, index1, index2) {
      let file = this.tableList[index1].tableHead[index2] + "link";
      // console.log(this.$fileurl + val[file])
      return val[file];
    },
    // 是否为数字
    tableTime(val, index1, index2) {
      let type = this.tableList[index1].tableHead[index2] + "type";
      // console.log(val[type].substring(0, val[type].indexOf(',')))
      return val[type].substring(0, val[type].indexOf(","));
    },
    // 控制合并
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row["Datetype"] !== undefined) {
        if (columnIndex === 0) {
          if (rowIndex % this.tableMerge === 0) {
            return {
              rowspan: this.tableMerge,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }
      // if (row['Datetype'] !== undefined) {
      //   if (columnIndex === 0) {
      //     if (rowIndex % this.tableList[0].tableListValue.length === 0) {
      //       return {
      //         rowspan: this.tableList[0].tableListValue.length,
      //         colspan: 1
      //       };
      //     } else {
      //       return {
      //         rowspan: 0,
      //         colspan: 0
      //       };
      //     }
      //   }
      // }
      else {
        console.log(123);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
    // console.log(this.$route.query)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 监听地址变化
  watch: {
    $route(to, from) {
      // console.log(to.query.id, this.pageId)
      // console.log(to.query.id.indexOf(this.pageId))
      if (to.query.id.indexOf(this.pageId) == -1) {
        this.init();
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.journaling {
  text-align: left;
  .section_banner {
    background-image: url("../../../public/home/img/bg-section-banner-contact.jpg");
  }
  .jouMain {
    .header {
      border-bottom: 1px solid rgb(48, 130, 192);
      padding-bottom: 20px;
      margin-bottom: 20px;
      span {
        font-size: 38px;
        border-bottom: 10px solid rgb(48, 130, 192);
        padding-bottom: 12px;
        color: rgb(48, 130, 192);
        font-weight: bold;
        padding-right: 40px;
      }
    }
    & /deep/ .has-gutter {
      font-size: 16px;
      color: #5a5a5a;
    }
    .time {
      font-size: 48px;
      font-weight: bold;
      line-height: 48px;
    }
    .link {
      font-size: 14px;
      color: #5a5a5a;
      &:hover {
        color: #47cdff;
      }
    }
  }
}
</style>